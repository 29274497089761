import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SalonLocator from 'components/SalonLocator';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function SalonLocatorPage(props) {
    let {
        data: { allGoogleSheetSheet1Row, contentfulPage },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'salon-locator'}
            />
            <SalonLocator salons={allGoogleSheetSheet1Row.nodes} />
        </Layout>
    );
}

/*
  query {
    allContentfulSalon {
      nodes {
        name
        address
      }
    }
  }
*/

export const query = graphql`
    query($locale: String) {
        allGoogleSheetSheet1Row {
            nodes {
                salonName
                street
                city
                state
                zip
                phone
                website
                lat
                lng
            }
        }
        contentfulPage(
            title: { eq: "Salon Locator" }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulPageFields
        }
    }
`;
