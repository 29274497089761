import React, { useState, useEffect } from 'react';

import SalonLocatorMap from './SalonLocatorMap';
import SalonLocatorList from './SalonLocatorList';

import { getUserLocation, getFilteredSalons } from './helpers';
import { useIntl } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    SharedStyles,
    Container,
    FieldInput,
    Fieldset,
    FieldSubmit,
    FormSearchStore,
} from '../../kenra-storybook/index';

const { StSectionInfo, StTitle } = SharedStyles;

export default function SalonLocator({ salons }) {
    const [search, setSearch] = useState('');
    const [filteredSalons, setFilteredSalons] = useState([]);
    const [searchFieldValue, setSearchFieldValue] = useState('');
    const [userLocation, setUserLocation] = useState(null);
    const [isFiltering, setIsFiltering] = useState('false');

    let intl = useIntl();
    useEffect(() => {
        getUserLocation().then(newUserLocation =>
            setUserLocation(newUserLocation)
        );
    }, []);

    useEffect(() => {
        setIsFiltering('true');
        getFilteredSalons(salons, search, intl)
            .then(newFilteredSalons => {
                setFilteredSalons(newFilteredSalons);
            })
            .then(() => {
                return new Promise(resolve => setTimeout(resolve, 500));
            })
            .then(() => {
                setIsFiltering('false');
            });
    }, [salons, search]);

    async function onSearch(e) {
        e.preventDefault();
        setSearch(searchFieldValue);
    }

    return (
        <>
            <Spacing removeSpaceBottom>
                <Container useMediumWidth>
                    <FormSearchStore>
                        <StSectionInfo>
                            <StTitle colorBlack styleMedium>
                                <h1>
                                    {intl.formatMessage({
                                        id: 'salon-locator-title',
                                    })}
                                </h1>
                            </StTitle>
                        </StSectionInfo>

                        <form onSubmit={onSearch}>
                            <Fieldset
                                legend={intl.formatMessage({
                                    id: 'salon-locator-input-legend',
                                })}
                            >
                                <FieldInput
                                    type="text"
                                    id="form-zip-code"
                                    label={intl.formatMessage({
                                        id: 'salon-locator-input-label',
                                    })}
                                    value={searchFieldValue}
                                    onChange={e =>
                                        setSearchFieldValue(e.target.value)
                                    }
                                />
                            </Fieldset>
                            <FieldSubmit>
                                <input
                                    value={intl.formatMessage({
                                        id: 'salon-locator-search-text',
                                    })}
                                    type="submit"
                                />
                            </FieldSubmit>
                        </form>
                    </FormSearchStore>
                </Container>
            </Spacing>

            <Spacing
                removeSpaceBottom={
                    !!(search && filteredSalons && isFiltering !== 'true')
                }
            >
                <SalonLocatorMap
                    salons={
                        search && filteredSalons && isFiltering !== 'true'
                            ? filteredSalons
                            : []
                    }
                    userLocation={userLocation}
                />
            </Spacing>

            {search && filteredSalons && isFiltering !== 'true' && (
                <SalonLocatorList
                    salons={filteredSalons}
                    search={searchFieldValue}
                />
            )}
        </>
    );
}
